@font-face {
  font-family: Averta;
  font-style: italic;
  font-weight: 100;
  src:
    url('./fonts/Averta-ThinItalic.woff2') format('woff2'),
    url('./fonts/Averta-ThinItalic.woff') format('woff');
}

@font-face {
  font-family: Averta;
  font-style: normal;
  font-weight: 100;
  src:
    url('./fonts/Averta-Thin.woff2') format('woff2'),
    url('./fonts/Averta-Thin.woff') format('woff');
}

@font-face {
  font-family: Averta;
  font-style: italic;
  font-weight: 400;
  src:
    url('./fonts/Averta-RegularItalic.woff2') format('woff2'),
    url('./fonts/Averta-RegularItalic.woff') format('woff');
}

@font-face {
  font-family: Averta;
  font-style: normal;
  font-weight: 400;
  src:
    url('./fonts/Averta-Regular.woff2') format('woff2'),
    url('./fonts/Averta-Regular.woff') format('woff');
}

@font-face {
  font-family: Averta;
  font-style: italic;
  font-weight: 600;
  src:
    url('./fonts/Averta-SemiboldItalic.woff2') format('woff2'),
    url('./fonts/Averta-SemiboldItalic.woff') format('woff');
}

@font-face {
  font-family: Averta;
  font-style: normal;
  font-weight: 600;
  src:
    url('./fonts/Averta-Semibold.woff2') format('woff2'),
    url('./fonts/Averta-Semibold.woff') format('woff');
}

@font-face {
  font-family: Averta;
  font-style: italic;
  font-weight: 700;
  src:
    url('./fonts/Averta-BoldItalic.woff2') format('woff2'),
    url('./fonts/Averta-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: Averta;
  font-style: normal;
  font-weight: 700;
  src:
    url('./fonts/Averta-Bold.woff2') format('woff2'),
    url('./fonts/Averta-Bold.woff') format('woff');
}

@font-face {
  font-family: Averta;
  font-style: italic;
  font-weight: 800;
  src:
    url('./fonts/Averta-ExtraBoldItalic.woff2') format('woff2'),
    url('./fonts/Averta-ExtraBoldItalic.woff') format('woff');
}

@font-face {
  font-family: Averta;
  font-style: normal;
  font-weight: 800;
  src:
    url('./fonts/Averta-ExtraBold.woff2') format('woff2'),
    url('./fonts/Averta-ExtraBold.woff') format('woff');
}

/*
 * AppleLogin button
 */
@font-face {
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/SFProText-Regular.woff') format('woff');
}
